import { Input } from '@redislabsdev/redislabs-ui-components';
import { useOktaAuth } from '@okta/okta-react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { ApiPaths } from 'services/api/apiPaths';
import { Button, Modal, TextButton } from '@redislabsdev/redis-ui-components';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { mfaPayloadSelector } from '../../../../../../store/auth/auth.selectors';
import RedisApiAxiosInstance from '../../../../../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';
import * as S from './SmsFactorForm.style';
import useFactorChange from '../../hooks/useFactorChange';
import useMfaForm from '../../hooks/useMfaForm';
import { extractError } from '../../../../../../utils/helpers/extractError';
import useLogOut from '../../../../../../hooks/useLogOut';

interface Props {
  switchToAuthAppModal: () => void;
}

const SmsFactorForm = ({ switchToAuthAppModal }: Props) => {
  const { oktaAuth, authState } = useOktaAuth();
  const { t } = useTranslation();
  const keyPrefix = i18translation.LoginScreen.mfaLogin.smsAuthenticationModal;
  const { phoneNumber, totpFactorAvailable, username, password } = useSelector(mfaPayloadSelector);
  const status = useFactorChange('SMS');
  const { handleAuthFactorSubmit } = useMfaForm();
  const [error, setError] = useState<string | null>(null);
  const reSendMfaCode = async () => {
    let authName = username?.toLowerCase();
    try {
      if (authState.isAuthenticated) {
        const user = await oktaAuth.getUser();
        authName = user?.email;
      }
      await RedisApiAxiosInstance.post(`${ApiPaths.LOGIN}/resend_mfa_code`, {
        username: authName,
        password
      });
    } catch (e) {
      const extractedError = extractError(e?.response?.data);
      setError(extractedError.message);
    }
  };
  const handleSubmit = ({ authCode }: { authCode: string }) => {
    handleAuthFactorSubmit('SMS', authCode);
  };
  const { performLogout } = useLogOut();

  return (
    <S.Container>
      <Modal.Content.Header title={t(keyPrefix.title)} />
      <Modal.Content.Body.Compose width="40rem">
        <Formik initialValues={{ authCode: '' }} onSubmit={handleSubmit}>
          {({ handleChange, dirty }) => (
            <Form>
              <S.InputHint>{t(keyPrefix.enterCode, { phoneNumber })}</S.InputHint>
              <Input
                data-testid="sms-code"
                id="sms-code"
                name="authCode"
                width="100%"
                onChange={handleChange}
              />
              <S.DidNotReceiveTheCodeContainer>
                {t(keyPrefix.didntReceive)}
                <S.ModalTextButton
                  data-testid="button--sms-code-again"
                  variant="primary-inline"
                  onClick={reSendMfaCode}
                >
                  {t(keyPrefix.sendCodeAgain)}
                </S.ModalTextButton>
              </S.DidNotReceiveTheCodeContainer>
              {!!error && <S.ErrorBanner show variant="danger" message={t(error)} />}
              <S.ModalContentFooterCompose>
                {totpFactorAvailable && (
                  <TextButton onClick={switchToAuthAppModal}>{t(keyPrefix.useAuthApp)}</TextButton>
                )}
                <S.ActionButtons>
                  <Button variant="secondary-ghost" onClick={() => performLogout()}>
                    {t('LoginScreen.mfaLogin.cancel')}
                  </Button>
                  <Button
                    type="submit"
                    disabled={!dirty || (totpFactorAvailable && status !== 'success')}
                  >
                    {t('LoginScreen.mfaLogin.verify')}
                  </Button>
                </S.ActionButtons>
              </S.ModalContentFooterCompose>
            </Form>
          )}
        </Formik>
      </Modal.Content.Body.Compose>
    </S.Container>
  );
};

export default SmsFactorForm;
